











































import Vue from 'vue';
import CardSnugglepot from "../cards/CardSnugglepot.vue";
import CardEcologist from "../cards/CardEcologist.vue";
import CardExploreSydneySwamp from "../cards/CardExploreSydneySwamp.vue";
export default Vue.extend<any, any, any, any>({
    props: {
        isActive: {
            type: Boolean,
            required: false,
        }
    },
    components: {
        CardSnugglepot,
        CardEcologist,
        CardExploreSydneySwamp,
    }
});
