<template>
    <ContentCard theme="brown" icon="assets/images/illu-fire.svg" class="w-70">
        <template #title>Damper and Popcorn</template>
        <template #description>
            Campfire cooking is fun and easy! Learn how to make damper and popcorn at home or out in the bush.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', '762Fyg9o_b0')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/damper-recipe.pdf" target="_blank"
                        icon="file" class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
