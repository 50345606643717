






































import Vue from 'vue';
import CardBringBackBush from "../cards/CardBringBackBush.vue";
import CardDingo from "../cards/CardDingo.vue";
import CardFrogs from "@/components/cards/CardFrogs.vue";

export default Vue.extend<any, any, any, any>({
    props: {
        isActive: {
            type: Boolean,
            required: false,
        }
    },
    components: {
        CardFrogs,
        CardBringBackBush,
        CardDingo,
    }
});
