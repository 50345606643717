

























import Vue from 'vue';
import lottie from 'lottie-web';

export default Vue.extend({
    //components: {LottieAnimation},
    mounted() {
        lottie.loadAnimation({
            container: this.$refs.dino as Element,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: require(`@/assets/dino.json`),
            rendererSettings: {
                preserveAspectRatio: 'xMidYMin slice',
                progressiveLoad: false,
                hideOnTransparent: true
            }
        });
    }
});
