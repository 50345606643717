

















import Vue from 'vue';

export default Vue.extend<any, any, any, any>({
    computed: {
        isVideo() {
            return this.$store.state.lightbox.length <= 20;
        }
    },
    mounted() {
        if (this.isVideo && typeof window !== 'undefined' && typeof (window as any).dataLayer !== 'undefined') {
            (window as any).dataLayer.push({'event': 'video', 'value': `https://www.youtube.com/${this.$store.state.lightbox}`});
        }
    }
});
