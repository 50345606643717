























































import Vue from 'vue';

export default Vue.extend({
    props: {
        slideIndex: {
            type: Number,
            default: 1,
        },
        lastIndex: {
            type: Number,
            default: 1,
        }
    },
    data: () => ({}),
    methods: {
        openRibbon() {
            this.$store.commit('TOGGLE_RIBBON');
            (window.dataLayer || []).push({'event': 'come_and_see'});
        }
    }
});
