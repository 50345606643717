<template>
    <ContentCard icon="assets/images/illu-cloud.svg">
        <template #title>Sensory Video</template>
        <template #description>
            See like me... Have you ever wondered what the world looks like for an insect, or a bird?
        </template>
        <template #buttons>
            <ButtonPill
                icon="play" class="card__btn me-8" @click.native="$store.commit('SET_LIGHTBOX', 'vkX6-6cNucw')">Watch
                Now
            </ButtonPill>
            <ButtonPill href="/pdfs/from-my-window.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';

export default {
    components: {ButtonPill, ContentCard}
};
</script>

<style scoped lang="scss">
::v-deep .card__illu {
    top: 25%;
    transform: none;
}
</style>
