import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        lightbox: '',
        ribbon: false,
    },
    mutations: {
        SET_LIGHTBOX: (state, video: string) => state.lightbox = video,
        CLOSE_LIGHTBOX: (state) => {
            state.lightbox = '';
            state.ribbon = false;
        },
        TOGGLE_RIBBON: (state) => state.ribbon = !state.ribbon,
    },
    actions: {},
    modules: {}
})
