<template>
    <ContentCard theme="brown" icon="assets/images/illu-rope.svg">
        <template #title>Tarps and Knots</template>
        <template #description>
            Practice your bush survival skills and build a sturdy tarp made with the right knots.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'IJMbK5h9Jr8')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/making-cordage.pdf" target="_blank"
                        icon="file" class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
