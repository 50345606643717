<template>
    <ContentCard theme="brown" icon="assets/images/illu-tree.svg" class="w-80">
        <template #title>Climb a tree</template>
        <template #description>
            Good climbing trees are everywhere, you just have to know how to spot them. Learn tree climbing tips and tricks from our Parklands Education Ranger.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'PwwP40o5_z4')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/tree-song.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
